export enum GameStatus {
  Play = 'Play',
  Win = 'Win',
  Fail = 'Fail',
}

export enum GameMode {
  OneWord = '每日一词',
  Free = '自由练习',
}

export interface LetterInfo {
  text: string // 单词，如 study
  status: string // 字母是否猜对， x为不存在; o为猜对; ?为位置不对，如 oxxox
  duplicates: string // 每个字母重复了几次，如00200
}

export interface Board {
  [row: number]: LetterInfo
}
