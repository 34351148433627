import { Box, HStack, VStack } from '@chakra-ui/react'
import { Board } from '../../interfaces/game'
import './index.css'
import { getViewHeight } from '../../utils/device'

interface GuestRowsProps {
  currentRow: number
  currentBoard: Board
  currentWordInput: string
  wordLength: number
  showDuplicates: boolean
  guestRounds: number
}

export const GuestRows = (props: GuestRowsProps) => {
  const { currentRow, currentBoard, currentWordInput, wordLength, showDuplicates, guestRounds } = props

  const getLetterBg = (letterStatus: string) => {
    switch (letterStatus) {
      // 只才对字母，但位置不对
      case '?':
        return 'orange.400'
      // 猜对字母和位置
      case 'o':
        return 'green.500'
      // 都未猜对
      default:
        return 'gray.400'
    }
  }

  const getGuestRow = (row: number) => {
    const wordLetters = []
    const isCurrentRow = currentRow === row
    const getChar = (charIdx: number) => {
      return isCurrentRow ? currentWordInput[charIdx] : currentBoard[row] ? currentBoard[row].text[charIdx] : ''
    }
    for (let i = 0; i < wordLength; i++) {
      wordLetters.push(
        <HStack
          id={`guest-row-${row}-word-letter-${i}`}
          key={`guest-row-${row}-word-letter-${i}`}
          h="12"
          w="12"
          align="center"
          justify="center"
          color="white"
          border="1px solid"
          borderWidth={getChar(i) ? '2px' : '1px'}
          borderColor={getChar(i) ? 'white' : 'gray.300'}
          animation={getChar(i) ? 'pulse 2s' : ''}
          rounded="lg"
          bg={currentRow > row ? getLetterBg(currentBoard[row].status[i]) : 'inherit'}
          position="relative"
        >
          <Box className={isCurrentRow && currentWordInput[i] ? 'animate__animated animate__bounceIn' : ''}>
            {getChar(i)}
          </Box>
          {showDuplicates && currentRow > row && (
            <Box position="absolute" top="0" right="1" fontSize="xs">
              {currentBoard[row].duplicates[i]}
            </Box>
          )}
        </HStack>
      )
    }
    return (
      <HStack key={`guest-row-${row}`} spacing="1" w="fit-content">
        {wordLetters}
      </HStack>
    )
  }

  const getGuestRows = () => {
    const guestRows = []
    for (let i = 0; i < guestRounds; i++) {
      guestRows.push(getGuestRow(i))
    }
    return guestRows
  }

  return (
    <VStack spacing={getViewHeight() > 750 ? 3 : 1} mx="auto" mb="8" w="fit-content" fontWeight="bold" fontSize="2xl">
      {getGuestRows()}
    </VStack>
  )
}
