export const getLetterDuplicationCount = (word: string, letter: string) => {
  let count = 0
  word.split('').forEach((item) => {
    if (item === letter) {
      count += 1
    }
  })
  return count
}

export const checkIsWordValid = (wordCheckList: string[], word: string) => {
  return wordCheckList.includes(word.toLowerCase())
}

export const getRandomWord = (words: string[], answerValue?: string) => {
  if (answerValue) {
    return answerValue
  }
  return words.length ? words[Math.floor(Math.random() * words.length)].toUpperCase() : ''
}
