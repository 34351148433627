import { Box, HStack } from '@chakra-ui/react'
import { clarity } from 'react-microsoft-clarity'
import ReactGA from 'react-ga4'

export const Footer = (props: { username: string }) => {
  const { username } = props
  return (
    <Box w="full" fontSize="md">
      <HStack color="white" fontSize="sm" w="fit-content" mx="auto" fontWeight="light" spacing="1">
        <Box>Made by</Box>
        <Box
          fontWeight="bold"
          display="inline-block"
          cursor="pointer"
          textDecorationLine="underline"
          onClick={() => {
            clarity.setTag('click', 'creator-link')
            ReactGA.event({
              category: 'visit-creator',
              action: username,
            })
            window.open('https://partymiao.cn/user/U2FsdGVkX1%2FVw2%2B9AUAWPPRV3%2BdVwUy9yChy4XQ2bFI%3D')
          }}
        >
          Krist
        </Box>
      </HStack>
    </Box>
  )
}
