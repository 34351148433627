import { Box, HStack } from '@chakra-ui/react'

interface TextWithColorStripParas {
  text: string
  bgGradient?: string
}
export const TextWithColorStrip = (paras: TextWithColorStripParas) => {
  const { text, bgGradient = 'linear(to-r, orange.300, pink.400)' } = paras
  return (
    <HStack color="white" fontSize="md" spacing="1" cursor="pointer" position="relative">
      <Box zIndex="1" fontWeight="bold">
        {text}
      </Box>
      <Box position="absolute" h="2" bgGradient={bgGradient} right="-15%" bottom="0" w="130%" borderRadius="2xl"></Box>
    </HStack>
  )
}
