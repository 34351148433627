import { Box, HStack, VStack, Image } from '@chakra-ui/react'
import { HelpTip } from './HelpTip'

export const Header = () => {
  return (
    <HStack>
      <Image
        height="14"
        width="14"
        rounded="xl"
        src="favicon/android-chrome-512x512.png"
        alt="Wordle Icon"
        shadow="xl"
      />
      <VStack spacing="-1">
        <HStack spacing={{ base: 2, md: 4 }}>
          <Box pl="4" fontSize="4xl" color="white" textShadow="1px 2px 2px #ffb69a">
            Wordle Game
          </Box>
          <HelpTip />
        </HStack>

        <Box color="gray.200" fontSize="sm" fontWeight="normal">
          wordle.partymiao.cn
        </Box>
      </VStack>
    </HStack>
  )
}
