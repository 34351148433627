export const resetKeyboardBg = () => {
  window.document.querySelectorAll('.hg-button.hg-standardBtn').forEach((ele) => {
    ele.setAttribute('class', 'hg-button hg-standardBtn')
  })
}

export const setKeyBgToMuted = (button: string) => {
  const ele = Array.from(document.querySelectorAll('.hg-button.hg-standardBtn')).find(
    (el) => el.childNodes[0].textContent === button
  )
  ele?.classList.add('not-in')
}
