import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'animate.css'
import Wordle from './Wordle'
import { ChakraProvider } from '@chakra-ui/react'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Wordle />
    </ChakraProvider>
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y256FP85M4"></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-Y256FP85M4');
          gtag('test', 'test-value')
        `,
      }}
    ></script> */}
  </React.StrictMode>
)
