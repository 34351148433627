import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Box, Tooltip, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { LogoBgColor, LogoColor } from '../constants/theme'

export const HelpTip = () => {
  const [showHelpTip, setShowHelpTip] = useState<boolean>(false)
  const getHelpTipContent = () => {
    return (
      <VStack spacing="1" align="left">
        <Box fontWeight="bold" fontSize="md" color={LogoColor}>
          玩法介绍:
        </Box>
        <Box>输入完一行后点击Enter提交猜测:</Box>
        <Box fontSize="xs" color="gray.400">
          友情提示：电脑端可使用键盘
        </Box>
        <Box pl="2">
          - 若答案中没有该字母则会被标记为
          <Box display="inline" color="gray.400">
            灰色
          </Box>
          ;
        </Box>
        <Box pl="2">
          - 若有相同字母但位置不同会被标记为
          <Box display="inline" color="orange.400">
            橙色
          </Box>
          ;
        </Box>
        <Box pl="2">
          - 若字母和位置都相同则会被标记为
          <Box display="inline" color="green.500">
            绿色
          </Box>
          ;
        </Box>

        <Box>猜出答案或使用完猜测次数则游戏结束。</Box>

        {/* 图片示例 */}
        <Box color="orange.500" fontWeight="bold">
          示例如下:(红框内为谜题答案)
        </Box>
        <img
          src="https://party-miao-prod-cos-1259311192.cos.ap-guangzhou.myqcloud.com/static/wordle/play-help.png"
          alt="play-help"
        />
      </VStack>
    )
  }
  return (
    <Tooltip isOpen={showHelpTip} label={getHelpTipContent()}>
      <Box
        cursor="pointer"
        pt="2"
        onMouseEnter={() => setShowHelpTip(true)}
        onMouseLeave={() => setShowHelpTip(false)}
        onClick={() => setShowHelpTip(true)}
      >
        <QuestionOutlineIcon style={{ fontSize: '1.3rem', color: LogoBgColor }} />
      </Box>
    </Tooltip>
  )
}
