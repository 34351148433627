export type VocabularyType =
  | 'primary'
  | 'junior'
  | 'senior'
  | 'CET4'
  | 'CET6'
  | 'CET8'
  | 'IELTS'
  | 'TOEFL'
  | 'SAT'
  | 'all'

export const importWords = async (folderName: string) => {
  const words: string[] = await import(`./${folderName}/answer-words`).then((module) => module.default.wordList)
  return words
}

// 词库信息
export const VocabularyDict: { [key: string]: { title: string; color: string; getWords: () => Promise<string[]> } } = {
  primary: {
    title: '小学',
    color: 'green.400',
    getWords: () => importWords('primary'),
  },
  junior: {
    title: '初中',
    color: 'green.500',
    getWords: () => importWords('junior'),
  },
  senior: {
    title: '高中',
    color: 'green.600',
    getWords: () => importWords('senior'),
  },
  CET4: {
    title: '四级',
    color: 'blue.400',
    getWords: () => importWords('CET4'),
  },
  CET6: {
    title: '六级',
    color: 'blue.500',
    getWords: () => importWords('CET6'),
  },
  CET8: {
    title: '考研',
    color: 'blue.600',
    getWords: () => importWords('CET8'),
  },
  IELTS: {
    title: 'IELTS',
    color: 'yellow.500',
    getWords: () => importWords('IELTS'),
  },
  TOEFL: {
    title: 'TOEFL',
    color: 'orange.500',
    getWords: () => importWords('TOEFL'),
  },
  SAT: {
    title: 'SAT',
    color: 'red.500',
    getWords: () => importWords('SAT'),
  },
}
