import {
  Box,
  VStack,
  HStack,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  RadioGroup,
  Radio,
  Switch,
  Divider,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  Button,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import checkWords from './lib/words/check-words'
import { SettingsIcon } from '@chakra-ui/icons'
import JSConfetti from 'js-confetti'
import { VocabularyDict, VocabularyType } from './lib/words/vocabulary'
import { Footer } from './components/Footer'
import { Board, GameMode, GameStatus, LetterInfo } from './interfaces/game'
import { Keyboard } from './components/Keyboard'
import { resetKeyboardBg, setKeyBgToMuted } from './utils/keyboard'
import { getViewHeight } from './utils/device'
import { GuestRows } from './components/GuestRows'
import { Answer } from './components/Answer'
import { Header } from './components/Header'
import { ToastParas } from './interfaces/toast'
import { checkIsWordValid, getLetterDuplicationCount, getRandomWord } from './utils/word'
import { TextWithColorStrip } from './components/TextWithColorStrip'
import configs from '../package.json'
import { clarity } from 'react-microsoft-clarity'
import ReactGA from 'react-ga4'

// 用于开发调试
const showAnswer = false
const answerValue = '' // 指定答案单词

const emptyBoardRow: LetterInfo = { text: '', status: '', duplicates: '' }

function Wordle() {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const onCloseModal = () => setIsOpenModal(false)
  const [inputUsername, setInputUsername] = useState<string>('')
  const [username, setUsername] = useState<string>('')

  const showConfetti = useCallback(() => {
    new JSConfetti().addConfetti()
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      clarity.init(process.env.REACT_APP_CLARIFY_K as string)
      ReactGA.initialize(process.env.REACT_APP_GA4_ID as string)
    }
  }, [])

  const toast = useToast()
  const handleToast = useCallback(
    (paras: ToastParas) => {
      const toastId = 'toast-id'
      if (toast.isActive(toastId)) {
        toast.close(toastId)
      }
      // 不使用setTimeout会导致新窗口也被关闭
      setTimeout(() => {
        toast({
          id: toastId,
          title: paras.title,
          description: paras.description,
          status: paras.status,
          duration: paras.duration || paras.duration === null ? null : 3000,
          isClosable: paras.closable || true,
          position: 'top',
        })
      })
    },
    [toast]
  )
  // 浏览器可视高度
  const viewHeight = getViewHeight()

  const [vocabulary, setVocabulary] = useState<VocabularyType>('primary')
  const [wordLength, setWordLength] = useState<number>(5)
  const [wordList, setWordList] = useState<string[]>([])

  const [answer, setAnswer] = useState<string>('')
  const [gameStatus, setGameStatus] = useState<GameStatus>(GameStatus.Play)
  const [gameMode, setGameMode] = useState<GameMode>(GameMode.Free)

  const [guestRounds, setGuestRounds] = useState<number>(6)
  const [guessedKeys, setGuessedKeys] = useState<string>('')

  // const [noDuplicateLetters, setNoDuplicateLetters] = useState<boolean>(false)
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false)

  const [currentRow, setCurrentRow] = useState<number>(0)
  const [currentWordInput, setCurrentWordInput] = useState<string>('')
  const [currentBoard, setCurrentBoard] = useState<Board>({
    0: emptyBoardRow,
  })

  const setKeyBg = useCallback(
    (currentRowInfo: { text: string; status: string }) => {
      let newGuessedKeys = ''
      for (let idx = 0; idx < wordLength; idx++) {
        const guessedKey = currentRowInfo.text[idx]
        if (guessedKeys.includes(guessedKey)) {
          continue
        }
        newGuessedKeys += guessedKey
        if (currentRowInfo.status[idx] === 'x') {
          setKeyBgToMuted(guessedKey)
        }
      }
      setGuessedKeys(guessedKeys + newGuessedKeys)
    },
    [guessedKeys, wordLength]
  )

  // 处理游戏结束和胜利
  useEffect(() => {
    switch (gameStatus) {
      case GameStatus.Win:
        showConfetti()
        handleToast({
          title: 'Nice Job!',
          description: 'You guessed the right word 🎉',
          status: 'success',
        })
        break
      case GameStatus.Fail:
        handleToast({
          title: 'It Was Close...',
          description: (
            <VStack align="left">
              <HStack>
                <Box>The Answer is:</Box>
                <Box fontWeight="bold">{answer}</Box>
              </HStack>
            </VStack>
          ),
          status: 'warning',
        })
        break
      default:
        break
    }
  }, [gameStatus, answer, handleToast, showConfetti])

  const restartGame = useCallback(
    (newWordList?: string[]) => {
      setCurrentRow(0)
      setCurrentWordInput('')
      setCurrentBoard({ 0: emptyBoardRow })
      // 重置键盘样式
      setGuessedKeys('')
      setGameStatus(GameStatus.Play)
      resetKeyboardBg()
      toast.closeAll()
      setAnswer(getRandomWord(newWordList || wordList, answerValue))
    },
    [toast, wordList]
  )

  // 根据词库和单词长度更新单词列表
  useEffect(() => {
    VocabularyDict[vocabulary].getWords().then((newVocabulary) => {
      const newWords = newVocabulary.filter((word) => word.length === wordLength)
      setWordList(newWords)
    })
  }, [vocabulary, wordLength])

  // 每次单词列表变化，则重置游戏
  useEffect(() => {
    if (gameStatus === GameStatus.Play) {
      restartGame(wordList)
    }
  }, [restartGame, wordList, gameStatus])

  const onKeyReleased = useCallback(
    (button: string) => {
      if (isOpenModal) {
        return
      }
      if (button === '{enter}') {
        // 游戏结束时回车自动开始新游戏
        if (gameStatus !== GameStatus.Play) {
          restartGame()
          return
        }
        // 长度未达到，直接返回
        if (currentWordInput.length !== wordLength) {
          return
        }
        // 不在词列表中，直接返回
        if (!checkIsWordValid(checkWords.wordList, currentWordInput)) {
          handleToast({
            title: 'Word is Not Valid',
            description: 'This word is not in the word list.',
            status: 'error',
          })
          return
        }
        // 计算单词猜测结果并更新到currentBoard[currentRow]
        let buttonStatus = ''
        let duplicates = ''
        for (let i = 0; i < wordLength; i++) {
          buttonStatus += currentWordInput[i] === answer[i] ? 'o' : answer.includes(currentWordInput[i]) ? '?' : 'x'
          duplicates += getLetterDuplicationCount(answer, currentWordInput[i])
        }
        const currentRowData = {
          text: currentWordInput,
          status: buttonStatus,
          duplicates: duplicates,
        }
        setKeyBg(currentRowData)
        // 进行下一行的猜题
        const newRow = currentRow + 1
        setCurrentRow(newRow)
        setCurrentWordInput('')
        setCurrentBoard({
          ...currentBoard,
          [currentRow]: currentRowData,
          [newRow]: emptyBoardRow,
        })
        const guessedValues = `${Object.values(currentBoard)
          .map((item) => item.text)
          .join(',')}`
        // 猜对，直接结束
        if (currentWordInput === answer) {
          clarity.setTag('success', guessedValues + `|answer=${answer}`)
          ReactGA.event({
            category: 'success',
            action: guessedValues + `|answer=${answer}`,
          })
          setGameStatus(GameStatus.Win)
          return
        }
        // 已用掉所有机会
        if (newRow === guestRounds) {
          clarity.setTag('fail', guessedValues + `|answer=${answer}`)
          ReactGA.event({
            category: 'fail',
            action: guessedValues + `|answer=${answer}`,
          })
          setGameStatus(GameStatus.Fail)
          return
        }
        clarity.setTag('guess', guessedValues + `|answer=${answer}`)
        ReactGA.event({
          category: 'guess',
          action: guessedValues + `|answer=${answer}`,
        })
      } else if (button === '{backspace}') {
        setCurrentWordInput(currentWordInput.slice(0, currentWordInput.length - 1))
        return
      } else {
        // 已达到最大长度
        if (currentWordInput.length === wordLength) {
          return
        }
        const newInput = currentWordInput + button
        setCurrentWordInput(newInput)
      }
    },
    [
      isOpenModal,
      currentBoard,
      currentRow,
      currentWordInput,
      guestRounds,
      setKeyBg,
      answer,
      wordLength,
      handleToast,
      gameStatus,
      restartGame,
    ]
  )

  useEffect(() => {
    const keyUpEventListener = (e: any) => {
      const key = e.key
      if (key === ' ') {
        return
      } else if (/^[a-zA-Z\s]$/.test(key)) {
        onKeyReleased(e.key.toUpperCase())
      } else if (key === 'Enter') {
        onKeyReleased('{enter}')
      } else if (key === 'Backspace') {
        onKeyReleased('{backspace}')
      }
    }
    window.addEventListener('keyup', keyUpEventListener, { passive: false })
    return () => {
      window.removeEventListener('keyup', keyUpEventListener)
    }
  }, [onKeyReleased])

  // 光标动画
  useEffect(() => {
    let cursorRow = currentRow
    let cursorCol = currentWordInput.length

    document.getElementById('cursor_elem')?.remove()
    const targetItem = document.getElementById(`guest-row-${cursorRow}-word-letter-${cursorCol}`)
    if (targetItem && gameStatus === GameStatus.Play) {
      const cursorElem = document.createElement('div')
      cursorElem.setAttribute('id', 'cursor_elem')
      cursorElem.style.position = 'absolute'
      cursorElem.style.right = '15%'
      cursorElem.style.bottom = '8px'
      cursorElem.style.width = '70%'
      cursorElem.style.height = '2px'
      cursorElem.style.backgroundColor = 'white'
      cursorElem.style.borderRadius = '8px'
      cursorElem.style.padding = '0 8px'
      cursorElem.classList.add('animate__animated', 'animate__fadeIn', 'animate__infinite', 'animate__slow')
      targetItem.append(cursorElem)
    }
  }, [currentRow, currentWordInput, gameStatus])

  const getSettingArea = () => {
    return (
      <HStack spacing="8">
        <Box
          fontSize="sm"
          fontWeight="bold"
          color="white"
          bg="orange.500"
          px="2"
          py="1"
          shadow="lg"
          rounded="xl"
          cursor="pointer"
          onClick={() => setIsOpenModal(true)}
        >
          {username.length > 5 ? username.substring(0, 5) + '...' : username || '点击留名'}
        </Box>
        <Menu>
          <MenuButton>
            <TextWithColorStrip text={gameMode} />
          </MenuButton>
          <MenuList fontSize="sm" minW="40">
            {Object.entries(GameMode).map((modeData) => (
              <MenuItem
                isDisabled={modeData[1] === GameMode.OneWord}
                key={`game-mode-${modeData[0]}`}
                onClick={() => setGameMode(modeData[1])}
              >
                <HStack w="full" justify="space-between">
                  <Box>{modeData[1]}</Box>
                  {modeData[1] === GameMode.OneWord && <Box fontSize="xs">敬请期待</Box>}
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton hidden={gameMode !== GameMode.Free}>
            <HStack color="white" fontSize="md" spacing="1" cursor="pointer">
              <Box>设置</Box>
              <SettingsIcon />
            </HStack>
          </MenuButton>
          <MenuList fontSize="sm" minW="70">
            <MenuItem>
              <HStack>
                <Box mr="4">词库</Box>
                <RadioGroup
                  flex="1"
                  colorScheme="green"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value: VocabularyType) => setVocabulary(value)}
                  value={vocabulary}
                >
                  <VStack align="left">
                    {[0, 1, 2].map((row) => {
                      return (
                        <HStack key={`vocabulary-setting-row-${row}`} flexWrap="wrap" justify="space-around">
                          {Object.keys(VocabularyDict)
                            .slice(row * 3, (row + 1) * 3)
                            .map((vocabulary) => {
                              return (
                                <Radio key={`vocabulary-${vocabulary}`} value={vocabulary}>
                                  <Box fontSize="sm" color={VocabularyDict[vocabulary].color}>
                                    {VocabularyDict[vocabulary].title}
                                  </Box>
                                </Radio>
                              )
                            })}
                        </HStack>
                      )
                    })}
                  </VStack>
                </RadioGroup>
              </HStack>
            </MenuItem>

            <Divider></Divider>

            <MenuItem>
              <HStack>
                <Box mr="4">单词长度</Box>
                <RadioGroup
                  flex="1"
                  colorScheme="green"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(value) => {
                    setWordLength(parseInt(value))
                    // setGuestRounds(parseInt(value))
                  }}
                  value={wordLength.toString()}
                >
                  <HStack spacing="3">
                    {[4, 5, 6, 7].map((_length) => (
                      <Radio key={`word-length-option-${_length}`} value={`${_length}`}>
                        {_length}
                      </Radio>
                    ))}
                  </HStack>
                </RadioGroup>
              </HStack>
            </MenuItem>

            <MenuItem>
              <HStack
                justify="space-between"
                w="full"
                onClick={(e) => {
                  setShowDuplicates(!showDuplicates)
                  e.stopPropagation()
                }}
              >
                <Box mr="4">显示字母重复次数</Box>
                <Box pointerEvents="none">
                  <Switch colorScheme="green" isChecked={showDuplicates} />
                </Box>
              </HStack>
            </MenuItem>

            <MenuItem>
              <HStack w="full" justify="space-between" onClick={(e) => e.stopPropagation()}>
                <Box>Version</Box>
                <Box>{configs.version}</Box>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    )
  }

  return (
    <VStack
      backgroundImage="linear-gradient(to right , #667eea, #764ba2)"
      h={viewHeight}
      w="100vw"
      py={viewHeight > 750 ? 4 : 2}
      spacing={viewHeight > 750 ? 8 : 4}
      justify="space-between"
    >
      {/* 顶部区 */}
      <VStack spacing={viewHeight >= 750 ? 2 : 1}>
        <Header />
        {getSettingArea()}
      </VStack>

      {/* 猜词区 */}
      <GuestRows
        currentRow={currentRow}
        currentBoard={currentBoard}
        currentWordInput={currentWordInput}
        wordLength={wordLength}
        showDuplicates={showDuplicates}
        guestRounds={guestRounds}
      />

      {/* 显示答案：调试时使用 */}
      {showAnswer && <Answer answer={answer} />}

      {/* 键盘区 */}
      <Keyboard gameStatus={gameStatus} restartGame={restartGame} onKeyReleased={onKeyReleased} />
      {/* 底部区 */}
      <Footer username={username} />

      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent width={300}>
          <ModalHeader>留名</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder="请输入你的名字" onChange={(e) => setInputUsername(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              onClick={() => {
                setUsername(inputUsername)
                clarity.setTag('username', inputUsername)
                ReactGA.gtag('set', 'user_properties', {
                  username: inputUsername,
                })
                setIsOpenModal(false)
              }}
            >
              确认
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default Wordle
