import { Box, HStack } from '@chakra-ui/react'
import { GameStatus } from '../../interfaces/game'
import ReactKeyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import './index.css'

interface KeyboardProps {
  gameStatus: GameStatus
  restartGame: () => void
  onKeyReleased: (button: string) => void
}

export const Keyboard = (props: KeyboardProps) => {
  const { gameStatus, restartGame, onKeyReleased } = props

  return (
    <Box zIndex="1" w={{ base: 'full', md: '600px' }} fontSize="md" position="relative">
      <HStack
        cursor="pointer"
        align="center"
        justify="center"
        h="100%"
        w="100%"
        color="white"
        borderRadius="5px"
        bg={gameStatus === GameStatus.Play ? 'transparent' : 'gray.600'}
        opacity="0.85"
        zIndex={gameStatus === GameStatus.Play ? -1 : 0}
        _hover={{ bg: 'gray.500' }}
        onClick={restartGame}
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="bold"
        position="absolute"
        transitionDuration="200ms"
      >
        <Box>Click here to start a new game</Box>
      </HStack>

      <ReactKeyboard
        theme={'hg-theme-default myTheme1'}
        layout={{
          default: ['Q W E R T Y U I O P', 'A S D F G H J K L', '{backspace} Z X C V B N M {enter}'],
        }}
        display={{
          '{enter}': 'Enter',
          '{backspace}': 'Delete ⌫',
        }}
        onKeyReleased={onKeyReleased}
      />
    </Box>
  )
}
